<template>
    <div class="collection-page bg-light">
        <section class="page-kv">
            <img :src="houseImage.bannerPc" :alt="meta.alt.alt_2_1" class="w-100 d-none d-md-inline-block" v-if="houseImage.bannerPc != ''">
        </section>
        <div class="collection-search d-none d-md-block mt-negative bg-wh">
            <div class="container">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link to="/">首頁</router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">買屋</li>
                    </ol>
                </nav>
                <div class="search-panel" v-if="width > 767">
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button :class="'nav-link' + categoryClass[0]" id="searchAll-tab" data-bs-toggle="pill" category="0" type="button"
                                    role="tab" aria-controls="searchAll"
                                    aria-selected="true" @click="changeCategory(0)">全部
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button :class="'nav-link' + categoryClass[1]" id="searchHouse-tab" data-bs-toggle="pill" category="1" type="button"
                                    role="tab"
                                    aria-controls="searchHouse" aria-selected="false" @click="changeCategory(1)">房屋
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button :class="'nav-link' + categoryClass[2]" id="searchLand-tab" data-bs-toggle="pill" category="2" type="button"
                                    role="tab"
                                    aria-controls="searchLand" aria-selected="false" @click="changeCategory(2)">土地
                            </button>
                        </li>
                    </ul>
                    <div class="searchTab-content tab-content">
                        <div class="tab-pane fade show active" id="searchAll" role="tabpanel">
                            <div class="search-form-wrapper">
                                <div class="form-group form-search-group">
                                    <label class="visually-hidden" for="searchInput">關鍵字搜尋</label>
                                    <i class="icon">
                                        <IconSearch/>
                                    </i>
                                    <input type="text" class="form-control rounded-input" id="searchInput" placeholder="請輸入物件編號、物件名稱、路名、社區名稱"
                                           @keyup="searchKeyword($event)"
                                           v-model="checkedList.keyword">
                                </div>

                                <div class="row g-2 mb-2">
                                    <div class="form-group dropdown-wrapper col-auto dropdown-1">
                                        <div class="d-grid gap-2" @click="handleOpenSelect(1)">
                                            <div :class="'btn btn-select' + (checkedList.checkedCity != 0 ? ' selected' : '')">
                                                地區
                                                <i class="icon">
                                                    <IconSortDown class="down"/>
                                                    <IconSortUp class="up"/>
                                                </i>
                                            </div>
                                        </div>
                                        <div :class="'select-dropdown region_dropdown p-0' + (townList.length > 0 ? ' show_result' : '')">
                                            <div class="region_dropdown-row">
                                                <ul id="accordionRegion" class="accordion_region accordion accordion-flush region-list">
                                                    <template v-for="(regionName, regionIndex) in regionList">
                                                        <li class="item" role="presentation" v-if="cityList[regionIndex].length > 0">
                                                            <button type="button" :class="'nav-link' + (regionIndex == 0 ? ' active' : '')"
                                                                    id="north-tab" :region="regionIndex"
                                                                    data-bs-toggle="collapse"
                                                                    :data-bs-target="'#region-' + regionIndex"
                                                                    :aria-expanded="regionIndex == 0 ? 'true' : 'false'"
                                                                    aria-controls="north" :aria-selected="regionIndex == 0 ? 'true' : 'false'">
                                                                {{ regionName }}
                                                                <i class="icon">
                                                                    <IconSortDown class="down"/>
                                                                    <IconSortUp class="up"/>
                                                                </i>
                                                            </button>
                                                            <div :id="'region-' + regionIndex"
                                                                 :class="'accordion-collapse collapse' + (regionIndex == 0 ? ' show' : '')"
                                                                 data-bs-parent="#accordionRegion">
                                                                <div class="accordion-body pt-1">
                                                                    <div class="row gx-3 gy-2">
                                                                        <div class="col-6" v-for="(city, index) in cityList[regionIndex]">
                                                                            <button :class="'btn btn-checkbox w-100 ' + city.active + city.selected"
                                                                                    data-name="city"
                                                                                    :id="'city-' + regionIndex +'-' + index" @click="getTown(city)">
                                                                                {{ city.city }}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </template>
                                                </ul>
                                                <div class="result_region" v-if="townList.length > 0">
                                                    <div class="form-check form-check-inline" v-if="townList.length > 1">
                                                        <input class="form-check-input" type="checkbox" id="townUnlimited" value="1"
                                                               @click="changeTownUnlimited()"
                                                               v-model="townUnlimited">
                                                        <label class="form-check-label" for="townUnlimited">全選</label>
                                                    </div>
                                                    <hr>
                                                    <div class="row g-1">
                                                        <div class="col-4" v-for="(town, index) in townList">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" name="town[]" :id="'town-' + index"
                                                                       :value="town"
                                                                       @click="changeTown();" v-model="checkedList.checkedTown">
                                                                <label class="form-check-label" :for="'town-' + index">{{ town }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group dropdown-wrapper col-auto dropdown-2">
                                        <div class="d-grid gap-2" @click="handleOpenSelect(2)">
                                            <div :class="'btn btn-select' + (checkedList.checkedHouseType.length > 0 ? ' selected' : '')">
                                                型態
                                                <i class="icon">
                                                    <IconSortDown class="down"/>
                                                    <IconSortUp class="up"/>
                                                </i>
                                            </div>
                                        </div>
                                        <div class="select-dropdown">
                                            <div class="form-content">
                                                <div class="row g-1">
                                                    <div class="col-4" v-for="(houseType, index) in houseTypeList[category]">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="houseType[]"
                                                                   :id="'houseType-' + index"
                                                                   :value="houseType.typeName" v-model="checkedList.checkedHouseType"
                                                                   @click="changeChecked">
                                                            <label class="form-check-label" :for="'houseType-' + index">{{
                                                                    houseType.typeName
                                                                }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group dropdown-wrapper col-auto dropdown-3">
                                        <div class="d-grid gap-2" @click="handleOpenSelect(3)">
                                            <div :class="'btn btn-select' + (checkedList.checkedPrice.length > 0 ? ' selected' : '')">
                                                價格
                                                <i class="icon">
                                                    <IconSortDown class="down"/>
                                                    <IconSortUp class="up"/>
                                                </i>
                                            </div>
                                        </div>
                                        <div class="select-dropdown">
                                            <div class="row g-1">
                                                <div class="col-6" v-for="(price, index) in priceList">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" name="price[]" :id="'price-' + index"
                                                               :value="index + 1"
                                                               @click="changeChecked()" v-model="checkedList.checkedPrice">
                                                        <label class="form-check-label" :for="'price-' + index">{{ price }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="other-input-group d-flex align-items-center">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" id="price-8" value="9"
                                                                   v-model="checkedList.checkedPrice">
                                                            <label class="form-check-label" for="price-8"></label>
                                                        </div>
                                                        <div class="start">
                                                            <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('price')"
                                                                   @keyup="changeChecked()"
                                                                   v-model="checkedList.priceS">
                                                        </div>
                                                        <span class="px-2">-</span>
                                                        <div class="end">
                                                            <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('price')"
                                                                   @keyup="changeChecked()"
                                                                   v-model="checkedList.priceE">
                                                        </div>
                                                        <span class="px-2">萬</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="form-group dropdown-wrapper col-auto dropdown-4" v-if="category != 2">
                                        <div class="d-grid gap-2" @click="handleOpenSelect(4)">
                                            <div :class="'btn btn-select' + (checkedList.checkedRoom.length > 0 ? ' selected' : '')">
                                                格局
                                                <i class="icon">
                                                    <IconSortDown class="down"/>
                                                    <IconSortUp class="up"/>
                                                </i>
                                            </div>
                                        </div>
                                        <div class="select-dropdown">
                                            <div class="row g-1">
                                                <div class="col-3" v-for="(room, index) in roomList">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" name="room" :id="'room-' + index"
                                                               :value="index + 1"
                                                               @click="changeChecked()" v-model="checkedList.checkedRoom">
                                                        <label class="form-check-label" :for="'room-' + index">{{ room }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="other-input-group d-flex align-items-center">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" id="room-4" value="5"
                                                                   v-model="checkedList.checkedRoom">
                                                            <label class="form-check-label" for="room-5"></label>
                                                        </div>
                                                        <div class="start">
                                                            <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('room')"
                                                                   @keyup="changeChecked()"
                                                                   v-model="checkedList.roomS">
                                                        </div>
                                                        <span class="px-2">-</span>
                                                        <div class="end">
                                                            <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('room')"
                                                                   @keyup="changeChecked()"
                                                                   v-model="checkedList.roomE">
                                                        </div>
                                                        <span class="px-2">房</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group dropdown-wrapper col-auto dropdown-5" v-if="category != 2">
                                        <div class="d-grid gap-2" @click="handleOpenSelect(5)">
                                            <div :class="'btn btn-select' + (checkedList.checkedAge.length > 0 ? ' selected' : '')">
                                                屋齡
                                                <i class="icon">
                                                    <IconSortDown class="down"/>
                                                    <IconSortUp class="up"/>
                                                </i>
                                            </div>
                                        </div>
                                        <div class="select-dropdown">
                                            <div class="row g-1">
                                                <div class="col-4" v-for="(age, index) in ageList">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" :id="'age-' + index" :value="index + 1"
                                                               @click="changeChecked()"
                                                               v-model="checkedList.checkedAge">
                                                        <label class="form-check-label" :for="'age-' + index">{{ age }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="other-input-group d-flex align-items-center">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" id="age-5" value="6"
                                                                   v-model="checkedList.checkedAge">
                                                            <label class="form-check-label" for="age-6"></label>
                                                        </div>
                                                        <div class="start">
                                                            <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('age')"
                                                                   @keyup="changeChecked()"
                                                                   v-model="checkedList.ageS">
                                                        </div>
                                                        <span class="px-2">-</span>
                                                        <div class="end">
                                                            <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('age')"
                                                                   @keyup="changeChecked()"
                                                                   v-model="checkedList.ageE">
                                                        </div>
                                                        <span class="px-2">年</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group dropdown-wrapper col-auto dropdown-6" v-if="category != 2">
                                        <div class="d-grid gap-2" @click="handleOpenSelect(6)">
                                            <div :class="
                                                    checkedList.checkedFloor.length > 0 ||
                                                    checkedList.checkedTotalPing.length > 0 ||
                                                    checkedList.checkedBuildingPing.length > 0 ||
                                                    checkedList.checkedLandPing.length > 0 ||
                                                    checkedList.checkedParking !== 0
                                                    ? 'btn btn-select selected' : 'btn btn-select'">
                                                進階搜尋
                                                <i class="icon">
                                                    <IconSortDown class="down"/>
                                                    <IconSortUp class="up"/>
                                                </i>
                                            </div>
                                        </div>
                                        <div class="select-dropdown">
                                            <div class="advanced-search-form">
                                                <!-- 樓層 -->
                                                <h3>樓層</h3>
                                                <div class="row row-cols-xl-5 row-cols-lg-2 pb-3" id="advanced2">
                                                    <div class="col" v-for="(floor, index) in floorList">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" :id="'floor-' + index" :value="index + 1"
                                                                   @click="changeChecked()"
                                                                   v-model="checkedList.checkedFloor">
                                                            <label class="form-check-label" :for="'floor-' + index">{{ floor }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="other-input-group d-inline-flex align-items-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" id="floor-4" value="5"
                                                                       v-model="checkedList.checkedFloor">
                                                                <label class="form-check-label" for="floor-4"></label>
                                                            </div>
                                                            <div class="start">
                                                                <input type="text" class="form-control form-control-sm"
                                                                       @focus="checkFocusOther('floor')" @keyup="changeChecked()"
                                                                       v-model="checkedList.floorS">
                                                            </div>
                                                            <span class="px-2">-</span>
                                                            <div class="end">
                                                                <input type="text" class="form-control form-control-sm"
                                                                       @focus="checkFocusOther('floor')" @keyup="changeChecked()"
                                                                       v-model="checkedList.floorE">
                                                            </div>
                                                            <span class="px-2">樓</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- 總坪數 -->
                                                <h3>總坪數</h3>
                                                <div class="row row-cols-xl-5 row-cols-lg-2 pb-3" id="advanced3">
                                                    <div class="col" v-for="(totalPing, index) in totalPingList">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" :id="'totalPing-' + index"
                                                                   :value="index + 1"
                                                                   @click="changeChecked()" v-model="checkedList.checkedTotalPing">
                                                            <label class="form-check-label" :for="'totalPing-' + index">{{ totalPing }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="other d-inline-flex align-items-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" id="totalPing-5" value="6"
                                                                       v-model="checkedList.checkedTotalPing">
                                                                <label class="form-check-label" for="totalPing-5"></label>
                                                            </div>
                                                            <div class="other-input-group d-inline-flex align-items-center">
                                                                <div class="start">
                                                                    <input type="text" class="form-control form-control-sm"
                                                                           @focus="checkFocusOther('totalPing')"
                                                                           @keyup="changeChecked()" v-model="checkedList.totalPingS">
                                                                </div>
                                                                <span class="px-2">-</span>
                                                                <div class="end">
                                                                    <input type="text" class="form-control form-control-sm"
                                                                           @focus="checkFocusOther('totalPing')"
                                                                           @keyup="changeChecked()" v-model="checkedList.totalPingE">
                                                                </div>
                                                                <span class="px-2">坪</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- 主建坪 -->
                                                <h3>主建坪</h3>
                                                <div class="row row-cols-xl-5 row-cols-lg-2 pb-3" id="advanced4">
                                                    <div class="col" v-for="(buildingPing, index) in buildingPingList">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" :id="'buildingPing-' + index"
                                                                   :value="index + 1"
                                                                   @click="changeChecked()" v-model="checkedList.checkedBuildingPing">
                                                            <label class="form-check-label" :for="'buildingPing-' + index">{{ buildingPing }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="other d-inline-flex align-items-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" id="buildingPing-5" value="6"
                                                                       v-model="checkedList.checkedBuildingPing">
                                                                <label class="form-check-label" for="buildingPing-5"></label>
                                                            </div>
                                                            <div class="other-input-group d-inline-flex align-items-center">
                                                                <div class="start">
                                                                    <input type="text" class="form-control form-control-sm"
                                                                           @focus="checkFocusOther('buildingPing')"
                                                                           @key="changeChecked()" v-model="checkedList.buildingPingS">
                                                                </div>
                                                                <span class="px-2">-</span>
                                                                <div class="end">
                                                                    <input type="text" class="form-control form-control-sm"
                                                                           @focus="checkFocusOther('buildingPing')"
                                                                           @keyup="changeChecked()" v-model="checkedList.buildingPingE">
                                                                </div>
                                                                <span class="px-2">坪</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- 土地坪 -->
                                                <h3>土地坪</h3>
                                                <div class="row row-cols-xl-5 row-cols-lg-2 pb-3" id="advanced5">
                                                    <div class="col" v-for="(landPing, index) in landPingList">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" :id="'landPing-' + index"
                                                                   :value="index + 1"
                                                                   @click="changeChecked()"
                                                                   v-model="checkedList.checkedLandPing">
                                                            <label class="form-check-label" :for="'landPing-' + index">{{ landPing }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="other d-inline-flex align-items-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" id="landPing-5" value="6"
                                                                       v-model="checkedList.checkedLandPing">
                                                                <label class="form-check-label" for="landPing-5"></label>
                                                            </div>
                                                            <div class="other-input-group d-inline-flex align-items-center">
                                                                <div class="start">
                                                                    <input type="text" class="form-control form-control-sm"
                                                                           @focus="checkFocusOther('landPing')"
                                                                           @keyup="changeChecked()"
                                                                           v-model="checkedList.landPingS">
                                                                </div>
                                                                <span class="px-2">-</span>
                                                                <div class="end">
                                                                    <input type="text" class="form-control form-control-sm"
                                                                           @focus="checkFocusOther('landPing')"
                                                                           @keyup="changeChecked()"
                                                                           v-model="checkedList.landPingE">
                                                                </div>
                                                                <span class="px-2">坪</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- 車位 -->
                                                <h3>車位</h3>
                                                <div class="row row-cols-xl-5 row-cols-lg-2">
                                                    <div class="col" v-for="(parking, index) in parkingList">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" :id="'parking-' + index" :value="index + 1"
                                                                   @click="changeChecked()"
                                                                   v-model="checkedList.checkedParking">
                                                            <label class="form-check-label" :for="'parking-' + index">{{ parking }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- 全景 -->
                                                <h3>全景</h3>
                                                <div class="row row-cols-xl-5 row-cols-lg-2">
                                                    <div class="col" v-for="(vr, index) in vrList">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" :id="'vr-' + index" :value="index + 1"
                                                                   @click="changeChecked()"
                                                                   v-model="checkedList.checkedVr">
                                                            <label class="form-check-label" :for="'vr-' + index">{{ vr }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h3>租售</h3>
                                                <div class="row row-cols-xl-5 row-cols-lg-2">
                                                    <div class="col" v-for="(sell, index) in sellList">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="radio" :id="'sell-' + index" :value="index + 1"
                                                                   @click="changeChecked()"
                                                                   v-model="checkedList.checkedSell">
                                                            <label class="form-check-label" :for="'sell-' + index">{{ sell }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group dropdown-wrapper col-auto dropdown-7" v-if="category == 2">
                                        <div class="d-grid gap-2" @click="handleOpenSelect(7)">
                                            <div :class="'btn btn-select' + (checkedList.checkedLandPing.length > 0 ? ' selected' : '')">
                                                土地坪
                                                <i class="icon">
                                                    <IconSortDown class="down"/>
                                                    <IconSortUp class="up"/>
                                                </i>
                                            </div>
                                        </div>
                                        <div class="select-dropdown">
                                            <div class="advanced-search-form">
                                                <div class="row row-cols-xl-5 row-cols-lg-4 row-cols-md-2 pb-3">
                                                    <div class="col" v-for="(landPing, index) in landPingList">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" :id="'landPing-' + index"
                                                                   :value="index + 1"
                                                                   @click="changeChecked()" v-model="checkedList.checkedLandPing">
                                                            <label class="form-check-label" :for="'landPing-' + index">{{ landPing }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="other d-inline-flex align-items-center">
                                                            <div class="other-input-group d-inline-flex align-items-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox" id="LangPing-6" value="7"
                                                                           v-model="checkedList.checkedLandPing">
                                                                    <label class="form-check-label" for="LangPing-6"></label>
                                                                </div>
                                                                <div class="start">
                                                                    <input type="text" class="form-control form-control-sm"
                                                                           @focus="checkFocusOther('landPing')"
                                                                           @keyup="changeChecked()" v-model="checkedList.landPingS">
                                                                </div>
                                                                <span class="px-2">-</span>
                                                                <div class="end">
                                                                    <input type="text" class="form-control form-control-sm"
                                                                           @focus="checkFocusOther('landPing')"
                                                                           @keyup="changeChecked()" v-model="checkedList.landPingE">
                                                                </div>
                                                                <span class="px-2">坪</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="search-tags pt-3">
                                    <div class="d-flex align-items-center mb-2">
                                        <h4 class="title me-2 mb-0">已選條件({{ searchTagList.length }})</h4>
                                        <button type="button" class="btn clear-all" @click="clear()" v-if="searchTagList.length > 0">
                                            <span class="text">清除全部</span>
                                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M1.77631 13.462C1.77631 14.3203 2.47859 15.0226 3.33693 15.0226H9.5794C10.4377 15.0226 11.14 14.3203 11.14 13.462V4.09828H1.77632V13.462H1.77631ZM11.9203 1.75736H9.18924L8.40893 0.977051H4.50739L3.72708 1.75736H0.996002V3.31798H11.9203V1.75736Z"
                                                      fill="#999999"/>
                                            </svg>
                                        </button>
                                    </div>
                                    <div class="result-tags">
                                        <div class="btn btn-tags" v-for="(tag, index) in searchTagList">
                                            {{ tag.value }}
                                            <button class="close-circle" type="button" @click="deleteTag(index)"><i class="icon">
                                                <IconClose/>
                                            </i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="searchHouse" role="tabpanel" aria-labelledby="searchHouse-tab">...</div>
                        <div class="tab-pane fade" id="searchLand" role="tabpanel" aria-labelledby="searchLand-tab">...</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="collection-search-mobile" v-if="width < 768">
            <div class="container" id="mobileSearchContainer">
                <div class="btn-group w-100 btn-radio-tab" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" class="btn-check" category="0" name="category" id="category0" autocomplete="off" @click="changeCategory(0)">
                    <label class="btn btn-tab" for="category0">全部</label>
                    <input type="radio" class="btn-check" category="1" name="category" id="category1" autocomplete="off" @click="changeCategory(1)">
                    <label class="btn btn-tab" for="category1">房屋</label>
                    <input type="radio" class="btn-check" category="2" name="category" id="category2" autocomplete="off" @click="changeCategory(2)">
                    <label class="btn btn-tab" for="category2">土地</label>
                </div>
                <div class="search-group">
                    <div class="form-group form-search-group mb-3">
                        <i class="icon">
                            <IconSearch/>
                        </i>
                        <input type="text" class="form-control search-input" placeholder="請輸入物件編號、物件名稱、路名..." @keyup="changeChecked()"
                               v-model="checkedList.keyword">
                    </div>
                    <div class="btn-wrapper">
                        <button type="button" class="btn btn-primary w-100 big-r" data-bs-toggle="modal" data-bs-target="#mobileSearchModal">篩選條件
                        </button>
                    </div>
                    <hr class="mb-0 mt-3 search-tags-hr">
                    <div class="search-tags pt-3">
                        <div class="d-flex align-items-center justify-content-between mb-2">
                            <div class="d-flex align-items-center">
                                <h4 class="title mb-0">已選條件({{ searchTagList.length }})</h4>
                                <button type="button" class="btn clear-all" @click="clear()" v-if="searchTagList.length > 0">
                                    <span class="text">清除全部</span>
                                    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M1.77631 13.462C1.77631 14.3203 2.47859 15.0226 3.33693 15.0226H9.5794C10.4377 15.0226 11.14 14.3203 11.14 13.462V4.09828H1.77632V13.462H1.77631ZM11.9203 1.75736H9.18924L8.40893 0.977051H4.50739L3.72708 1.75736H0.996002V3.31798H11.9203V1.75736Z"
                                              fill="#999999"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="ml-auto btn btn-collapse" v-if="searchTagList.length > 0" data-bs-toggle="collapse" href="#mobileResultTags"
                                 role="button"
                                 aria-expanded="true" aria-controls="mobileResultTags">
                                <i class="icon">
                                    <IconSortDown class="down"/>
                                    <IconSortUp class="up"/>
                                </i>
                            </div>
                        </div>
                        <div class="result-tags collapse show" id="mobileResultTags">
                            <div class="btn btn-tags" v-for="(tag, index) in searchTagList">
                                {{ tag.value }}
                                <button class="close-circle" type="button" @click="deleteTag(index)"><i class="icon">
                                    <IconClose/>
                                </i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="collections pb-5">
            <div class="container">
                <div class="collections-view-filter d-flex align-items-center justify-content-between mb-2 mb-md-3">
                    <h4 id="totalRecord" class="total_record_title mb-0">總筆數：{{ totalRecord }}</h4>
                    <div class="view-switch ms-auto mb-0">
                        <button :class="'btn btn-link' + (mode == 1 ? ' active' : '')" @click="changeMode(1)">
                            <IconMap class="me-0 me-md-2"/>
                            <span class="text d-none d-md-inline-block">地圖模式</span>
                        </button>
                        <button :class="'btn btn-link sm-border' + (mode == 2 ? ' active' : '')" @click="changeMode(2)">
                            <IconGrid class="me-0 me-md-2"/>
                            <span class="text d-none d-md-inline-block">列表模式</span>
                        </button>
                    </div>
                </div>
                <!-- 地圖模式 -->
                <div :class="'map-view colleciotn-map-view' + (mode == 1 ? '' : ' map-hide')">
                    <div class="map-content">
                        <div id="map" class="collectionMap"></div>
                    </div>
                    <div class="map-list-group">
                        <div class="collection-map-list">
                            <CollectionMapList :siteName="siteName" :about="about" :item="item" :markerChecked="markerChecked" :showItem="checkedList.showItem"
                                               v-for="item in this.allHouseList"></CollectionMapList>
                        </div>
                    </div>
                </div>

                <!-- 列表模式 -->
                <div class="colleciotn-block-view" v-if="mode == 2">
                    <div class="row" v-if="totalRecord != ''">
                        <div class="col-12 col-md-4 col-lg-3" v-for="item in this.houseList">
                            <HouseCard
                                :siteName=siteName
                                :about=about
                                :item=item
                            ></HouseCard>
                        </div>
                    </div>
                </div>
                <nav aria-label="Page navigation" v-html="paginate" v-if="mode == 2"></nav>
            </div>
        </section>
        <div class="modal fade" id="mobileSearchModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" v-if="width < 768">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title w-100 text-center" id="exampleModalLabel">篩選條件</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <ul id="accordionMobile" class="accordion_mobile accordion accordion-flush region-list">
                            <li class="item" role="presentation">
                                <button type="button" class="nav-link w-100 active" region="0" data-bs-toggle="collapse" data-bs-target="#condition1"
                                        aria-expanded="false"
                                        aria-controls="north" aria-selected="false">縣市
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedList.checkedCity != 0">{{ checkedList.checkedCity }}</span>
                                        <span class="condition-text" v-else>不限</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition1" class="accordion-collapse collapse " data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <button type="button" :class="'btn btn-checkbox me-1 me-md-2 mb-2' + city.active + city.selected"
                                                v-for="(city, index) in cityList[0]"
                                                @click="getTown(city)">
                                            {{ city.city }}
                                        </button>
                                        <button type="button" :class="'btn btn-checkbox me-1 me-md-2 mb-2' + city.active + city.selected"
                                                v-for="(city, index) in cityList[1]"
                                                @click="getTown(city)">
                                            {{ city.city }}
                                        </button>
                                        <button type="button" :class="'btn btn-checkbox me-1 me-md-2 mb-2' + city.active + city.selected"
                                                v-for="(city, index) in cityList[2]"
                                                @click="getTown(city)">
                                            {{ city.city }}
                                        </button>
                                        <button type="button" :class="'btn btn-checkbox me-1 me-md-2 mb-2' + city.active + city.selected"
                                                v-for="(city, index) in cityList[3]"
                                                @click="getTown(city)">
                                            {{ city.city }}
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation">
                                <button class="nav-link w-100" region="1" type="button" data-bs-toggle="collapse" data-bs-target="#condition2"
                                        aria-expanded="false"
                                        aria-controls="central" aria-selected="false" ref="townList">區域
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedList.checkedTown.length == 0">不限</span>
                                        <span class="condition-text" v-else-if="checkedList.checkedTown.length == 1">{{
                                                checkedList.checkedTown[0]
                                            }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedList.checkedTown.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition2" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="form-check mb-3" v-if="townList.length > 1">
                                            <input class="form-check-input" type="checkbox" id="townUnlimited" value="1"
                                                   @click="changeTownUnlimited()" v-model="townUnlimited">
                                            <label class="form-check-label" for="townUnlimited" @click="changeChecked()">全選</label>
                                        </div>
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(town, index) in townList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" name="town[]" :id="'town-m-0-' + index"
                                                           :value="town" @click="changeChecked()"
                                                           v-model="checkedList.checkedTown">
                                                    <label class="form-check-label" :for="'town-m-0-' + index" @click="changeTown()">{{
                                                            town
                                                        }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation">
                                <button class="nav-link w-100" type="button" data-bs-toggle="collapse" data-bs-target="#condition3"
                                        aria-expanded="false"
                                        aria-controls="south" aria-selected="false">型態
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedList.checkedHouseType.length == 0">不限</span>
                                        <span class="condition-text"
                                              v-else-if="checkedList.checkedHouseType.length == 1">{{ checkedList.checkedHouseType[0] }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedList.checkedHouseType.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition3" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(houseType, index) in houseTypeList[category]">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" name="houseType[]" :id="'houseType-' + index"
                                                           :value="houseType.typeName"
                                                           v-model="checkedList.checkedHouseType" @click="changeChecked">
                                                    <label class="form-check-label" :for="'houseType-' + index"
                                                           @click="changeChecked()">{{ houseType.typeName }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation">
                                <button class="nav-link w-100" region="3" type="button" data-bs-toggle="collapse" data-bs-target="#condition4"
                                        aria-expanded="false"
                                        aria-controls="east" aria-selected="false">價格
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedList.checkedPrice.length == 0">不限</span>
                                        <span class="condition-text"
                                              v-else-if="checkedList.checkedPrice.length == 1">{{ priceList[checkedList.checkedPrice[0] - 1] }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedList.checkedPrice.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition4" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(price, index) in priceList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" name="price[]" :id="'price-' + index"
                                                           :value="index + 1"
                                                           @click="changeChecked()" v-model="checkedList.checkedPrice">
                                                    <label class="form-check-label" :for="'price-' + index" @click="changeChecked()">{{
                                                            price
                                                        }}</label>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex">
                                                <div class="other-input-group d-inline-flex align-items-center">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="price-8" value="9"
                                                               v-model="checkedList.checkedPrice">
                                                        <label class="form-check-label" for="price-8"></label>
                                                    </div>
                                                    <div class="start">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('price')"
                                                               @keyup="changeChecked()"
                                                               v-model="checkedList.priceS">
                                                    </div>
                                                    <span class="px-2">-</span>
                                                    <div class="end">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('price')"
                                                               @keyup="changeChecked()"
                                                               v-model="checkedList.priceE">
                                                    </div>
                                                    <span class="px-2">萬</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation" v-if="category != 2">
                                <button class="nav-link w-100" type="button" data-bs-toggle="collapse" data-bs-target="#condition5"
                                        aria-expanded="false"
                                        aria-controls="south" aria-selected="false">格局
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedList.checkedRoom.length == 0">不限</span>
                                        <span class="condition-text"
                                              v-else-if="checkedList.checkedRoom.length == 1">{{ roomList[checkedList.checkedRoom[0] - 1] }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedList.checkedRoom.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition5" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(room, index) in roomList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" name="room" :id="'room-' + index"
                                                           :value="index + 1" @click="changeChecked()"
                                                           v-model="checkedList.checkedRoom">
                                                    <label class="form-check-label" :for="'room-' + index" @click="changeChecked()">{{ room }}</label>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex">
                                                <div class="other-input-group d-inline-flex align-items-center">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="room-5" value="5"
                                                               v-model="checkedList.checkedRoom">
                                                        <label class="form-check-label" for="room-5"></label>
                                                    </div>
                                                    <div class="start">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('room')"
                                                               @keyup="changeChecked()"
                                                               v-model="checkedList.roomS">
                                                    </div>
                                                    <span class="px-2">-</span>
                                                    <div class="end">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('room')"
                                                               @keyup="changeChecked()"
                                                               v-model="checkedList.roomE">
                                                    </div>
                                                    <span class="px-2">房</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation" v-if="category != 2">
                                <button class="nav-link w-100" type="button" data-bs-toggle="collapse" data-bs-target="#condition6"
                                        aria-expanded="false"
                                        aria-controls="south" aria-selected="false">屋齡
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedList.checkedAge.length == 0">不限</span>
                                        <span class="condition-text"
                                              v-else-if="checkedList.checkedAge.length == 1">{{ ageList[checkedList.checkedAge[0] - 1] }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedList.checkedAge.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition6" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(age, index) in ageList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" :id="'age-' + index" :value="index + 1"
                                                           @click="changeChecked()"
                                                           v-model="checkedList.checkedAge">
                                                    <label class="form-check-label" :for="'age-' + index" @click="changeChecked()">{{ age }}</label>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex">
                                                <div class="other-input-group d-inline-flex align-items-center">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="age-6" value="6"
                                                               v-model="checkedList.checkedAge">
                                                        <label class="form-check-label" for="age-6"></label>
                                                    </div>
                                                    <div class="start">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('age')"
                                                               @keyup="changeChecked()"
                                                               v-model="checkedList.ageS">
                                                    </div>
                                                    <span class="px-2">-</span>
                                                    <div class="end">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('age')"
                                                               @keyup="changeChecked()"
                                                               v-model="checkedList.ageE">
                                                    </div>
                                                    <span class="px-2">年</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation" v-if="category != 2">
                                <button class="nav-link w-100" type="button" data-bs-toggle="collapse" data-bs-target="#condition7"
                                        aria-expanded="false"
                                        aria-controls="south" aria-selected="false">樓層
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedList.checkedFloor.length == 0">不限</span>
                                        <span class="condition-text"
                                              v-else-if="checkedList.checkedFloor.length == 1">{{ floorList[checkedList.checkedFloor[0] - 1] }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedList.checkedFloor.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition7" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(floor, index) in floorList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" :id="'floor-' + index" :value="index + 1"
                                                           v-model="checkedList.checkedFloor">
                                                    <label class="form-check-label" :for="'floor-' + index" @click="changeChecked()">{{
                                                            floor
                                                        }}</label>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex">
                                                <div class="other-input-group d-inline-flex align-items-center">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="floor-5" value="5"
                                                               v-model="checkedList.checkedFloor">
                                                        <label class="form-check-label" for="floor-5"></label>
                                                    </div>
                                                    <div class="start">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('floor')"
                                                               @keyup="changeChecked"
                                                               v-model="checkedList.floorS">
                                                    </div>
                                                    <span class="px-2">-</span>
                                                    <div class="end">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('floor')"
                                                               @keyup="changeChecked"
                                                               v-model="checkedList.floorE">
                                                    </div>
                                                    <span class="px-2">樓</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation" v-if="category != 2">
                                <button class="nav-link w-100" type="button" data-bs-toggle="collapse" data-bs-target="#condition8"
                                        aria-expanded="false"
                                        aria-controls="south" aria-selected="false">總坪數
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedList.checkedTotalPing.length == 0">不限</span>
                                        <span class="condition-text" v-else-if="checkedList.checkedTotalPing.length == 1">{{
                                                totalPingList[checkedList.checkedTotalPing[0] - 1]
                                            }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedList.checkedTotalPing.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition8" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(totalPing, index) in totalPingList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" :id="'totalPing-' + index" :value="index + 1"
                                                           @click="changeChecked()"
                                                           v-model="checkedList.checkedTotalPing">
                                                    <label class="form-check-label" :for="'totalPing-' + index" @click="changeChecked()">{{
                                                            totalPing
                                                        }}</label>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex">
                                                <div class="other-input-group d-inline-flex align-items-center">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="totalPing-6" value="6"
                                                               v-model="checkedList.checkedTotalPing">
                                                        <label class="form-check-label" for="totalPing-6"></label>
                                                    </div>
                                                    <div class="start">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('totalPing')"
                                                               @keyup="changeChecked()"
                                                               v-model="checkedList.totalPingS">
                                                    </div>
                                                    <span class="px-2">-</span>
                                                    <div class="end">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('totalPing')"
                                                               @keyup="changeChecked()"
                                                               v-model="checkedList.totalPingE">
                                                    </div>
                                                    <span class="px-2">坪</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation" v-if="category != 2">
                                <button class="nav-link w-100" type="button" data-bs-toggle="collapse" data-bs-target="#condition9"
                                        aria-expanded="false"
                                        aria-controls="south" aria-selected="false">主建坪
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedList.checkedBuildingPing.length == 0">不限</span>
                                        <span class="condition-text"
                                              v-else-if="checkedList.checkedBuildingPing.length == 1">{{
                                                buildingPingList[checkedList.checkedBuildingPing[0] - 1]
                                            }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedList.checkedBuildingPing.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition9" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(buildingPing, index) in buildingPingList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" :id="'buildingPing-' + index" :value="index + 1"
                                                           @click="changeChecked()"
                                                           v-model="checkedList.checkedBuildingPing">
                                                    <label class="form-check-label" :for="'buildingPing-' + index"
                                                           @click="changeChecked()">{{ buildingPing }}</label>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex">
                                                <div class="other-input-group d-inline-flex align-items-center">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="buildingPing-6" value="6"
                                                               v-model="checkedList.checkedBuildingPing">
                                                        <label class="form-check-label" for="buildingPing-6"></label>
                                                    </div>
                                                    <div class="start">
                                                        <input type="text" class="form-control form-control-sm"
                                                               @focus="checkFocusOther('buildingPing')" @keyup="changeChecked()"
                                                               v-model="checkedList.buildingPingS">
                                                    </div>
                                                    <span class="px-2">-</span>
                                                    <div class="end">
                                                        <input type="text" class="form-control form-control-sm"
                                                               @focus="checkFocusOther('buildingPing')" @keyup="changeChecked()"
                                                               v-model="checkedList.buildingPingE">
                                                    </div>
                                                    <span class="px-2">坪</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation">
                                <button class="nav-link w-100" type="button" data-bs-toggle="collapse" data-bs-target="#condition10"
                                        aria-expanded="false"
                                        aria-controls="south" aria-selected="false">土地坪
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedList.checkedLandPing.length == 0">不限</span>
                                        <span class="condition-text" v-else-if="checkedList.checkedLandPing.length == 1">{{
                                                landPingList[checkedList.checkedLandPing[0] - 1]
                                            }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedList.checkedLandPing.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition10" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(landPing, index) in landPingList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" :id="'landPing-' + index" :value="index + 1"
                                                           @click="changeChecked()"
                                                           v-model="checkedList.checkedLandPing">
                                                    <label class="form-check-label" :for="'landPing-' + index" @click="changeChecked()">{{
                                                            landPing
                                                        }}</label>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex">
                                                <div class="other-input-group d-inline-flex align-items-center">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="landPing-6" value="6"
                                                               v-model="checkedList.checkedLandPing">
                                                        <label class="form-check-label" for="landPing-6"></label>
                                                    </div>
                                                    <div class="start">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('landPing')"
                                                               @keyup="changeChecked()"
                                                               v-model="checkedList.landPingS">
                                                    </div>
                                                    <span class="px-2">-</span>
                                                    <div class="end">
                                                        <input type="text" class="form-control form-control-sm" @focus="checkFocusOther('landPing')"
                                                               @keyup="changeChecked()"
                                                               v-model="checkedList.landPingE">
                                                    </div>
                                                    <span class="px-2">坪</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation" v-if="category != 2">
                                <button class="nav-link w-100" type="button" data-bs-toggle="collapse" data-bs-target="#condition11"
                                        aria-expanded="false"
                                        aria-controls="south" aria-selected="false">車位
                                    <div class="acc-btn-right">
                                        <span class="condition-text">{{ parkingList[checkedList.checkedParking - 1] }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition11" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(parking, index) in parkingList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" :id="'parking-' + index" :value="index + 1"
                                                           @click="changeChecked()"
                                                           v-model="checkedList.checkedParking">
                                                    <label class="form-check-label" :for="'parking-' + index" @click="changeChecked()">{{
                                                            parking
                                                        }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation" v-if="category != 2">
                                <button class="nav-link w-100" type="button" data-bs-toggle="collapse" data-bs-target="#condition12"
                                        aria-expanded="false"
                                        aria-controls="south" aria-selected="false">全景
                                    <div class="acc-btn-right">
                                        <span class="condition-text">{{ vrList[checkedList.checkedVr - 1] }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition12" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(vr, index) in vrList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" :id="'vr-' + index" :value="index + 1"
                                                           @click="changeChecked()"
                                                           v-model="checkedList.checkedVr">
                                                    <label class="form-check-label" :for="'vr-' + index" @click="changeChecked()">{{ vr }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation">
                                <button class="nav-link w-100" type="button" data-bs-toggle="collapse" data-bs-target="#condition13"
                                        aria-expanded="false"
                                        aria-controls="south" aria-selected="false">租售
                                    <div class="acc-btn-right">
                                        <span class="condition-text">{{ sellList[checkedList.checkedSell - 1] }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition13" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(sell, index) in sellList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" :id="'sell-' + index" :value="index + 1"
                                                           @click="changeChecked()"
                                                           v-model="checkedList.checkedSell">
                                                    <label class="form-check-label" :for="'sell-' + index" @click="changeChecked()">{{ sell }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="modal-footer pt-1">
                        <div class="row g-2 w-100">
                            <div class="col-6">
                                <button class="btn btn-outline-secondary clear_result w-100" type="button" @click="clear">清空條件</button>
                            </div>
                            <div class="col-6">
                                <button class="btn btn-primary w-100 check_result" type="button" data-bs-dismiss="modal"
                                        @click="hideCollapse(); search(0); track('店頭-搜尋物件', '');">
                                    查看結果({{ totalRecord }})
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import CollectionMapList from '@/components/CollectionMapList.vue'
import ArrRight from '@/components/icons/ArrRight.vue'
import ArrDown from '@/components/icons/ArrDown.vue'
import IconPin from '@/components/icons/IconPin.vue'
import HouseCard from '@/components/HouseCard.vue'
import IconClose from '@/components/icons/IconClose.vue'
import IconSortUp from '@/components/icons/IconSortUp.vue'
import IconSortDown from '@/components/icons/IconSortDown.vue'
import IconSearch from '@/components/icons/IconSearch.vue'
import IconMap from '@/components/icons/IconMap.vue'
import IconGrid from '@/components/icons/IconGrid.vue'
import IconSort from '@/components/icons/IconSort.vue'

import "leaflet/dist/leaflet.css"
import L from 'leaflet';
import "leaflet.markercluster/dist/MarkerCluster.css"
import "leaflet.markercluster/dist/MarkerCluster.Default.css"
import "leaflet.markercluster";

export default {
    name: 'Collection',
    components: {
        CollectionMapList,
        ArrRight,
        ArrDown,
        IconPin,
        IconClose,
        IconSortUp,
        IconSearch,
        IconSortDown,
        HouseCard,
        IconMap,
        IconGrid,
        IconSort,
    },
    data() {
        return {
            siteName: '',
            meta: {},
            about: {},
            searchTagList: [],
            width: 0,
            currentPage: 0,
            category: 0,
            categoryClass: [
                '',
                '',
                '',
            ],
            houseImage: {
                bannerPc: '',
                bannerMobile: '',
            },
            mode: 2,
            region: 0,
            regionList: [],
            cityList: [],
            townUnlimited: 0,
            townList: [],
            houseTypeList: [],
            priceList: [],
            roomList: [],
            ageList: [],
            floorList: [],
            totalPingList: [],
            buildingPingList: [],
            landPingList: [],
            parkingList: [],
            vrList: [],
            sellList: [],
            allHouseList: [],
            houseList: [],
            areaList: [],
            totalRecord: '',
            paginate: '',

            checkedList: this.initChecked(),
            lastKeyword: '',
            isSearchKeyword: false,

            map: '',
            marker: '',
            divIcon: [],
            markerChecked: '',

            leaflet: {
                zoom: 16,
                center: [22.612961, 120.304167],
                houseLatLng: [22.612961, 120.304167],
                url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                attribution: `© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors`,
                options: {
                    scrollWheelZoom: false,
                },
                icon: {
                    type: [
                        "/map_icon/pin-food.png",
                        "/map_icon/pin-bag.png",
                        "/map_icon/pin-forest.png",
                        "/map_icon/pin-bus.png",
                        "/map_icon/pin-money.png",
                    ],
                    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
                    iconSize: [35, 45],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],
                    shadowSize: [41, 41]
                },
                markerList: [],
            },
        }
    },
    methods: {
        track: function (type, typeName) {
            if (this.about.ga != '') {
                ga('send', 'event', type, typeName);
            }
            if (this.about.pixel1 != '') {
                fbq('trackSingleCustom', this.about.pixel1, type, {houseType: typeName});
            }
            if (this.about.pixel2 != '') {
                fbq('trackSingleCustom', this.about.pixel2, type, {houseType: typeName});
            }
            if (type == '店頭-搜尋物件') {
                setTimeout(() => {
                    $('.btn-primary').blur()
                }, 500);
            }
        },
        initChecked: function () {
            return {
                keyword: '',
                checkedCity: 0,
                checkedTown: [],
                checkedHouseType: [],
                checkedPrice: [],
                priceS: '',
                priceE: '',
                checkedRoom: [],
                roomS: '',
                roomE: '',
                checkedAge: [],
                ageS: '',
                ageE: '',
                checkedFloor: [],
                floorS: '',
                floorE: '',
                checkedTotalPing: [],
                totalPingS: '',
                totalPingE: '',
                checkedBuildingPing: [],
                buildingPingS: '',
                buildingPingE: '',
                checkedLandPing: [],
                landPingS: '',
                landPingE: '',
                checkedParking: 0,
                checkedVr: 0,
                checkedSell: 0,
                showItem: [],
            }
        },
        changeMode: function (mode) {
            this.mode = mode;
        },
        hideCollapse: function () {
            $('#accordionMobile button.active').click();
        },
        handleOpenSelect: function (num) {
            let dropOpen = document.querySelector('.dropdown-wrapper');
            for (let x = 1; x <= 7; x++) {
                if (x != num) {
                    $('.dropdown-' + x).removeClass('dropOpen');
                }
            }
            if (event) {
                event.currentTarget.parentNode.classList.toggle('dropOpen')
            }
        },
        changeCategory: function (category) {
            this.category = category;
            this.search(0);
        },
        getTown: function (city) {
            for (let x = 0; x < 4; x++) {
                for (let y = 0; y < this.cityList[x].length; y++) {
                    this.cityList[x][y].active = '';
                    this.cityList[x][y].selected = '';
                    if (city.city == this.cityList[x][y].city) {
                        this.cityList[x][y].active = ' active';
                    }
                }
            }
            this.townList = city.town;
            this.checkedList.checkedCity = city.city;
            this.checkCitySelected();
            this.checkTownUnlimited();
            if (this.width < 768) {
                this.$refs.townList.click();
            }
        },
        checkCitySelected: function () {
            let addon = 0;
            this.countSelected = 0;
            for (let x = 0; x < 4; x++) {
                for (let y = 0; y < this.cityList[x].length; y++) {
                    this.cityList[x][y].selected = '';
                    for (let z = 0; z < this.cityList[x][y].town.length; z++) {
                        for (let index = 0; index < this.checkedList.checkedTown.length; index++) {
                            if (this.checkedList.checkedTown[index] == this.cityList[x][y].town[z] && this.cityList[x][y].selected != ' selected') {
                                if (this.cityList[x][y].active == '') {
                                    this.cityList[x][y].selected = ' selected';
                                    this.countSelected++;
                                } else {
                                    addon = 1;
                                }
                                break;
                            }
                        }
                    }
                }
            }
            this.countSelected += addon;
        },
        checkTownUnlimited: function () {
            let townListLength = this.townList.length;
            let checkedLength = 0;
            for (let x = 0; x < townListLength; x++) {
                for (let y = 0; y < this.checkedList.checkedTown.length; y++) {
                    if (this.townList[x] == this.checkedList.checkedTown[y]) {
                        checkedLength++;
                        break;
                    }
                }
            }
            this.townUnlimited = townListLength == checkedLength;
        },
        changeTownUnlimited: function () {
            setTimeout(() => {
                for (let x = 0; x < this.townList.length; x++) {
                    for (let y = 0; y < this.checkedList.checkedTown.length; y++) {
                        if (this.townList[x] == this.checkedList.checkedTown[y]) {
                            this.checkedList.checkedTown.splice(y, 1);
                            break;
                        }
                    }
                    if (this.townUnlimited == 1) {
                        this.checkedList.checkedTown.push(this.townList[x]);
                    }
                }
                this.search(0);
            }, 50);
        },
        changeTown: function () {
            setTimeout(() => {
                this.checkTownUnlimited();
                this.checkCitySelected();
                this.search(0);
            }, 50);
        },
        checkFocusOther: function (type) {
            let checked, index;
            switch (type) {
                case 'price':
                    checked = this.checkedList.checkedPrice;
                    index = 9;
                    break;
                case 'room':
                    checked = this.checkedRoom;
                    index = 5;
                    break;
                case 'age':
                    checked = this.checkedAge;
                    index = 6;
                    break;
                case 'floor':
                    checked = this.checkedFloor;
                    index = 5;
                    break;
                case 'totalPing':
                    checked = this.checkedTotalPing;
                    index = 6;
                    break;
                case 'buildingPing':
                    checked = this.checkedBuildingPing;
                    index = 6;
                    break;
                case 'landPing':
                    checked = this.checkedLandPing;
                    index = 6;
                    break;
            }
            for (let x = 0; x < checked.length; x++) {
                if (checked[x] == index) {
                    return false;
                }
            }
            checked.push(index);
        },
        searchKeyword: function (e) {
            setTimeout(() => {
                if (!this.isSearchKeyword) {
                    if (this.checkedList.keyword != this.lastKeyword) {
                        this.lastKeyword = this.checkedList.keyword;
                        this.isSearchKeyword = true;
                        // console.log(e);
                        this.search(0);
                    }
                }
            }, 200);
        },
        changeChecked: function () {
            this.search(0);
        },
        searchTag: function (key, value) {
            this.searchTagList.push({
                key: key,
                value: value
            })
        },
        deleteTag: function (index) {
            let tag = this.searchTagList[index];
            switch (tag.key) {
                case 'keyword':
                    this.checkedList.keyword = '';
                    break;
                case 'city':
                    let cityName = tag.value.split('全區')[0];
                    for (let x = 0; x < 4; x++) {
                        for (let y = 0; y < this.cityList[x].length; y++) {
                            if (this.cityList[x][y].city != cityName) {
                                continue;
                            }
                            for (let z = 0; z < this.cityList[x][y].town.length; z++) {
                                for (let index = 0; index < this.checkedList.checkedTown.length; index++) {
                                    if (this.checkedList.checkedTown[index] == this.cityList[x][y].town[z]) {
                                        this.checkedList.checkedTown.splice(index, 1);
                                    }
                                }
                            }
                        }
                    }
                    this.checkTownUnlimited();
                    break;
                case 'town':
                    for (let i = 0; i < this.checkedList.checkedTown.length; i++) {
                        if (this.checkedList.checkedTown[i] == tag.value) {
                            this.checkedList.checkedTown.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'houseType':
                    for (let i = 0; i < this.checkedList.checkedHouseType.length; i++) {
                        if (this.checkedList.checkedHouseType[i] == tag.value) {
                            this.checkedList.checkedHouseType.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'price':
                    for (let i = 0; i < this.checkedList.checkedPrice.length; i++) {
                        if (this.checkedList.checkedPrice[i] == tag.index) {
                            if (tag.index == 9) {
                                this.checkedList.priceS = '';
                                this.checkedList.priceE = '';
                            }
                            this.checkedList.checkedPrice.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'room':
                    for (let i = 0; i < this.checkedList.checkedRoom.length; i++) {
                        if (this.checkedList.checkedRoom[i] == tag.index) {
                            if (tag.index == 5) {
                                this.checkedList.roomS = '';
                                this.checkedList.roomE = '';
                            }
                            this.checkedList.checkedRoom.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'age':
                    for (let i = 0; i < this.checkedList.checkedAge.length; i++) {
                        if (this.checkedList.checkedAge[i] == tag.index) {
                            if (tag.index == 6) {
                                this.checkedList.ageS = '';
                                this.checkedList.ageE = '';
                            }
                            this.checkedList.checkedAge.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'floor':
                    for (let i = 0; i < this.checkedList.checkedFloor.length; i++) {
                        if (this.checkedList.checkedFloor[i] == tag.index) {
                            if (tag.index == 5) {
                                this.checkedList.floorS = '';
                                this.checkedList.floorE = '';
                            }
                            this.checkedList.checkedFloor.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'totalPing':
                    for (let i = 0; i < this.checkedList.checkedTotalPing.length; i++) {
                        if (this.checkedList.checkedTotalPing[i] == tag.index) {
                            if (tag.index == 6) {
                                this.checkedList.totalPingS = '';
                                this.checkedList.totalPingE = '';
                            }
                            this.checkedList.checkedTotalPing.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'buildingPing':
                    for (let i = 0; i < this.checkedList.checkedBuildingPing.length; i++) {
                        if (this.checkedList.checkedBuildingPing[i] == tag.index) {
                            if (tag.index == 6) {
                                this.checkedList.buildingPingS = '';
                                this.checkedList.buildingPingE = '';
                            }
                            this.checkedList.checkedBuildingPing.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'landPing':
                    for (let i = 0; i < this.checkedList.checkedLandPing.length; i++) {
                        if (this.checkedList.checkedLandPing[i] == tag.index) {
                            if (tag.index == 6) {
                                this.checkedList.landPingS = '';
                                this.checkedList.landPingE = '';
                            }
                            this.checkedList.checkedLandPing.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'parking':
                    this.checkedList.checkedParking = 0;
                    break;
                case 'vr':
                    this.checkedList.checkedVr = 0;
                    break;
                case 'sell':
                    this.checkedList.checkedSell = 0;
                    break;
            }
            this.search(0);
        },
        search: function (page, allow) {
            let pageCount = 16;
            if (this.currentPage == page && page != 0 && allow == undefined) {
                return false;
            }
            setTimeout(() => {
                this.searchTagList = [];
                if (this.checkedList.keyword != '') {
                    this.searchTagList.push({
                        key: 'keyword',
                        value: this.checkedList.keyword,
                    });
                }
                let checkedTown = [];
                for (let x = 0; x < this.checkedList.checkedTown.length; x++) {
                    checkedTown.push(this.checkedList.checkedTown[x]);
                }
                for (let x = 0; x < 4; x++) {
                    for (let y = 0; y < this.cityList[x].length; y++) {
                        let townCount = 0;
                        for (let z = 0; z < this.cityList[x][y].town.length; z++) {
                            for (let index = 0; index < checkedTown.length; index++) {
                                if (this.cityList[x][y].town[z] == checkedTown[index]) {
                                    townCount++;
                                }
                            }
                        }
                        if (this.cityList[x][y].town.length == townCount) {
                            this.searchTagList.push({
                                key: 'city',
                                value: this.cityList[x][y].city + '全區',
                            });

                            for (let z = 0; z < this.cityList[x][y].town.length; z++) {
                                for (let index = 0; index < checkedTown.length; index++) {
                                    if (this.cityList[x][y].town[z] == checkedTown[index]) {
                                        checkedTown.splice(index, 1);
                                    }
                                }
                            }
                        }
                    }
                }
                for (let i = 0; i < checkedTown.length; i++) {
                    this.searchTagList.push({
                        key: 'town',
                        value: checkedTown[i],
                    });
                }
                if (this.checkedList.checkedHouseType.length > 0) {
                    for (let i = 0; i < this.checkedList.checkedHouseType.length; i++) {
                        this.searchTagList.push({
                            key: 'houseType',
                            value: this.checkedList.checkedHouseType[i],
                        });
                    }
                }
                if (this.checkedList.checkedPrice.length > 0) {
                    this.searchTagAppend(this.priceList, this.checkedList.checkedPrice, 'price', 9, this.checkedList.priceS, this.checkedList.priceE, '萬');
                }
                if (this.checkedList.checkedRoom.length > 0) {
                    this.searchTagAppend(this.roomList, this.checkedList.checkedRoom, 'room', 5, this.checkedList.roomS, this.checkedList.roomE, '房');
                }
                if (this.checkedList.checkedAge.length > 0) {
                    this.searchTagAppend(this.ageList, this.checkedList.checkedAge, 'age', 6, this.checkedList.ageS, this.checkedList.ageE, '年');
                }
                if (this.checkedList.checkedFloor.length > 0) {
                    this.searchTagAppend(this.floorList, this.checkedList.checkedFloor, 'floor', 5, this.checkedList.floorS, this.checkedList.floorE, '樓');
                }
                if (this.checkedList.checkedTotalPing.length > 0) {
                    this.searchTagAppend(this.totalPingList, this.checkedList.checkedTotalPing, 'totalPing', 6, this.checkedList.totalPingS, this.checkedList.totalPingE, '萬');
                }
                if (this.checkedList.checkedBuildingPing.length > 0) {
                    this.searchTagAppend(this.buildingPingList, this.checkedList.checkedBuildingPing, 'buildingPing', 6, this.checkedList.buildingPingS, this.checkedList.buildingPingE, '坪');
                }
                if (this.checkedList.checkedLandPing.length > 0) {
                    this.searchTagAppend(this.landPingList, this.checkedList.checkedLandPing, 'landPing', 6, this.checkedList.landPingS, this.checkedList.landPingE, '坪');
                }
                if (this.checkedParking != 0) {
                    let checkedParking = this.checkedList.checkedParking - 1;
                    for (let i = 0; i < this.parkingList.length; i++) {
                        if (checkedParking == i) {
                            this.searchTagList.push({
                                key: 'parking',
                                value: this.parkingList[i] + '車位',
                            });
                            break;
                        }
                    }
                }
                if (this.checkedVr != 0) {
                    let checkedVr = this.checkedList.checkedVr - 1;
                    for (let i = 0; i < this.vrList.length; i++) {
                        if (checkedVr == i) {
                            this.searchTagList.push({
                                key: 'vr',
                                value: this.vrList[i] + '全景',
                            });
                            break;
                        }
                    }
                }
                if (this.checkedSell != 0) {
                    let checkedSell = this.checkedList.checkedSell - 1;
                    for (let i = 0; i < this.sellList.length; i++) {
                        if (checkedSell == i) {
                            this.searchTagList.push({
                                key: 'sell',
                                value: this.sellList[i],
                            });
                            break;
                        }
                    }
                }
                if (this.width > 767) {
                    $.each($('.btn-tab'), function (k, v) {
                        if (!$(this).hasClass('collapsed')) {
                            $(this).click();
                        }
                    });
                    pageCount = 16
                } else {
                    pageCount = 8;
                }
                if (page == 0) {
                    page = 1;
                }
                this.currentPage = page;
                let param = {
                    mode: this.mode,
                    category: this.category,
                    keyword: this.checkedList.keyword,
                    town: this.checkedList.checkedTown,
                    houseType: this.checkedList.checkedHouseType,
                    price: this.checkedList.checkedPrice,
                    priceS: this.checkedList.priceS,
                    priceE: this.checkedList.priceE,
                    room: this.checkedList.checkedRoom,
                    roomS: this.checkedList.roomS,
                    roomE: this.checkedList.roomE,
                    age: this.checkedList.checkedAge,
                    ageS: this.checkedList.ageS,
                    ageE: this.checkedList.ageE,
                    floor: this.checkedList.checkedFloor,
                    floorS: this.checkedList.floorS,
                    floorE: this.checkedList.floorE,
                    totalPing: this.checkedList.checkedTotalPing,
                    totalPingS: this.checkedList.totalPingS,
                    totalPingE: this.checkedList.totalPingE,
                    buildingPing: this.checkedList.checkedBuildingPing,
                    buildingPingS: this.checkedList.buildingPingS,
                    buildingPingE: this.checkedList.buildingPingE,
                    landPing: this.checkedList.checkedLandPing,
                    landPingS: this.checkedList.landPingS,
                    landPingE: this.checkedList.landPingE,
                    parking: this.checkedList.checkedParking,
                    vr: this.checkedList.checkedVr,
                    sell: this.checkedList.checkedSell,
                    page: page,
                    pageCount: pageCount,
                }
                this.axios.post('/api/houses/search', param)
                    .then(response => (
                        this.allHouseList = response.data.allHouseList,
                            this.houseList = response.data.houseList,
                            this.areaList = response.data.areaList,
                            this.paginate = response.data.paginate,
                            this.totalRecord = response.data.totalRecord,
                            this.leaflet.center = response.data.center,
                            window.history.pushState('page2', '買屋', '/collection' + response.data.query),
                            this.resetMarkerList(),
                            this.isSearchKeyword = false
                    ))
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                    .then(function () {
                        // always executed
                    });
            }, 200);
        },
        searchTagAppend: function (list, checkedList, key, otherIndex, dataS, dataE, wording) {
            for (let i = 0; i < checkedList.length; i++) {
                if (checkedList[i] == otherIndex) {
                    if (dataS != '' && dataE != '') {
                        this.searchTagList.push({
                            key: key,
                            value: dataS + '~' + dataE + wording,
                            index: checkedList[i],
                        });
                    } else if (dataS != '') {
                        this.searchTagList.push({
                            key: key,
                            value: dataS + wording + '以上',
                            index: checkedList[i],
                        });
                    } else if (dataE != '') {
                        this.searchTagList.push({
                            key: key,
                            value: dataE + wording + '以下',
                            index: checkedList[i],
                        });
                    }
                } else {
                    this.searchTagList.push({
                        key: key,
                        value: list[checkedList[i] - 1],
                        index: checkedList[i],
                    });
                }
            }
        },
        clear: function () {
            this.checkedList = this.initChecked()
            this.townList = [];
            this.townUnlimited = 0;
            this.search(0);
            setTimeout(() => {
                $('.clear-all').blur()
            }, 500)
        },
        resetMarkerList: function (resetCenter) {
            this.leaflet.markerList = [];
            if (resetCenter != 0) {
                this.map.setView(this.leaflet.center, this.leaflet.zoom);
            }
            this.marker = L.markerClusterGroup({
                zoomToBoundsOnClick: false,
                iconCreateFunction: function (cluster) {
                    let clusterSize = "small";
                    if (cluster.getChildCount() < 10000) {
                        clusterSize = "small";
                    }
                    return new L.DivIcon({
                        html: '<div><span>' + cluster.getChildCount() + '</span></div>',
                        className: 'marker-cluster marker-cluster-' + clusterSize,
                        iconSize: new L.Point(30, 30)
                    });
                }
            })

            this.marker.on('clusterclick', (event) => {
                // this.map.setZoom(17);
                this.checkedList.showItem = [];
                let markerList = event.layer.getAllChildMarkers();
                for (let x = 0; x < markerList.length; x++) {
                    console.log(markerList[x].options)
                    console.log(markerList[x].options.id)
                    this.checkedList.showItem.push(markerList[x].options.id);
                }
                setTimeout(() => {
                    this.map.setView(event.latlng, 17);
                }, 100)
            });
            for (let x = 0; x < this.allHouseList.length; x++) {
                let item = this.allHouseList[x];
                let marker = {
                    type: 0,
                    name: item.subject,
                    latLng: [item.lat, item.lng],
                };
                this.leaflet.markerList.push(marker);

                // L.marker([item.lat, item.lng], {title: item.subject}).addTo(this.map)
                //     .bindPopup('A pretty CSS3 popup.<br> Easily customizable.')
                //     .openPopup();
                let markerItem = L.marker([item.lat, item.lng], {
                    id: item.uuid,
                    title: item.subject,
                    icon: L.icon({
                        iconUrl: '/map_icon/pin.png',
                        iconSize: [21, 25],
                    })
                }).on('click', this.markerOnClick)
                markerItem.bindPopup(item.subject, {
                    offset: [0, 2],
                    closeButton: false,
                });
                // markerItem.on('click', function () {
                //     alert('Clicked on a member of the group!');
                // })
                this.marker.addLayer(markerItem);
                if (x == 0) {
                    // this.map.setView([item.lat, item.lng], this.leaflet.zoom)
                }
            }
            this.map.addLayer(this.marker);

        },
        markerOnClick: function (event) {
            // console.log(event.sourceTarget.options.id)
            this.markerChecked = event.sourceTarget.options.id;

            setTimeout(() => {
                $('.map-list-group').scrollTop($('.map-list-group').scrollTop() + $('.checked').position().top);
            }, 100);
        },
        zoomIn: function (event) {
            let city = event.sourceTarget.options.icon.options.city;
            let town = event.sourceTarget.options.icon.options.town;
            let center = event.sourceTarget.options.icon.options.center;
            console.log(center)
            this.checkedList.showItem = [];
            for (let x = 0; x < this.allHouseList.length; x++) {
                let row = this.allHouseList[x];
                if (row.city == city && row.town == town) {
                    this.checkedList.showItem.push(row.uuid);
                }
            }
            this.map.setView(center, 15);
            // this.map.setZoom(17);
            // this.map.panTo(center);
            // this.map.setZoom(17);
            // setTimeout(() => {
            //     this.map.setView(center, 17);
            // }, 100)
        },
        start: function () {
            let house = this;
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            let category = params.get("category");
            if (category != null) {
                this.category = category;
                if (this.width > 767) {
                    switch (parseInt(category)) {
                        case 0:
                            this.categoryClass[0] = ' active';
                            break;
                        case 1:
                            this.categoryClass[1] = ' active';
                            break;
                        case 2:
                            this.categoryClass[2] = ' active';
                            break;
                    }
                } else {
                    switch (parseInt(category)) {
                        case 0:
                        case 1:
                        case 2:
                            $('#category' + category).prop('checked', true)
                            break;
                    }
                }
            } else {
                if (this.width > 767) {
                    this.categoryClass[0] = ' active';
                } else {
                    $('#category0').prop('checked', true)
                }
            }
            let city = params.get("city");
            if (city != null) {
                this.checkedCity = city;
                for (let region = 0; region < 4; region++) {
                    $.each(this.cityList[region], function (k, v) {
                        if (v.city == city) {
                            house.getTown(v);
                        }
                    })
                }
            }
            let url = new URL(window.location);
            // let townUnlimited = params.get("townUnlimited");
            // if (townUnlimited != null) {
            //     this.townUnlimited = townUnlimited;
            // }
            let keyword = params.get("keyword");
            if (keyword != null) {
                this.checkedList.keyword = keyword;
            }
            let town = url.searchParams.getAll("town[]");
            if (town != null) {
                this.checkedList.checkedTown = town;
            }
            let houseType = url.searchParams.getAll("house_type[]");
            if (houseType != null) {
                this.checkedList.checkedHouseType = houseType;
            }
            let price = url.searchParams.getAll("price[]");
            if (price != null) {
                this.checkedPrice = price;
                let priceS = params.get("priceS");
                if (priceS != null) {
                    this.checkedList.priceS = priceS;
                }
                let priceE = params.get("priceE");
                if (priceE != null) {
                    this.checkedList.priceE = priceE;
                }
            }
            let room = url.searchParams.getAll("room[]");
            if (room != null) {
                this.checkedList.checkedRoom = room;
                let roomS = params.get("roomS");
                if (roomS != null) {
                    this.checkedList.roomS = roomS;
                }
                let roomE = params.get("roomE");
                if (roomE != null) {
                    this.checkedList.roomE = roomE;
                }
            }
            let age = url.searchParams.getAll("age[]");
            if (age != null) {
                this.checkedList.checkedAge = age;
                let ageS = params.get("ageS");
                if (ageS != null) {
                    this.checkedList.ageS = ageS;
                }
                let ageE = params.get("ageE");
                if (ageE != null) {
                    this.checkedList.ageE = ageE;
                }
            }
            let floor = url.searchParams.getAll("floor[]");
            if (floor != null) {
                this.checkedList.checkedFloor = floor;
                let floorS = params.get("floorS");
                if (floorS != null) {
                    this.checkedList.floorS = floorS;
                }
                let floorE = params.get("floorE");
                if (floorE != null) {
                    this.checkedList.floorE = floorE;
                }
            }
            let totalPing = url.searchParams.getAll("totalPing[]");
            if (totalPing != null) {
                this.checkedList.checkedTotalPing = totalPing;
                let totalPingS = params.get("totalPingS");
                if (totalPingS != null) {
                    this.checkedList.totalPingS = totalPingS;
                }
                let totalPingE = params.get("totalPingE");
                if (totalPingE != null) {
                    this.checkedList.totalPingE = totalPingE;
                }
            }
            let buildingPing = url.searchParams.getAll("buildingPing[]");
            if (buildingPing != null) {
                this.checkedList.checkedBuildingPing = buildingPing;
                let buildingPingS = params.get("buildingPingS");
                if (buildingPingS != null) {
                    this.checkedList.buildingPingS = buildingPingS;
                }
                let buildingPingE = params.get("buildingPingE");
                if (buildingPingE != null) {
                    this.checkedList.buildingPingE = buildingPingE;
                }
            }
            let landPing = url.searchParams.getAll("landPing[]");
            if (landPing != null) {
                this.checkedList.checkedLandPing = landPing;
                let landPingS = params.get("landPingS");
                if (landPingS != null) {
                    this.checkedList.landPingS = landPingS;
                }
                let landPingE = params.get("landPingE");
                if (landPingE != null) {
                    this.checkedList.landPingE = landPingE;
                }
            }
            let parking = params.get("parking");
            if (parking != null) {
                this.checkedList.checkedParking = parking;
            }
            let vr = params.get("vr");
            if (vr != null) {
                this.checkedList.checkedVr = vr;
            }
            let sell = params.get("sell");
            if (sell != null) {
                this.checkedList.checkedSell = sell;
            }
            let page = params.get("page");
            if (page != null) {
                this.currentPage = page;
                house.search(this.currentPage, true)
            } else {
                house.search(this.currentPage)
            }
        }
    },
    mounted() {
        let house = this;
        this.width = $(window).width();
        this.axios.get('/api/houses/init')
            .then(response => (
                this.siteName = response.data.siteName,
                    this.meta = response.data.meta,
                    this.about = response.data.about,
                    this.houseImage = response.data.houseImage,
                    this.regionList = response.data.regionList,
                    this.cityList = response.data.cityList,
                    this.houseTypeList = response.data.houseTypeList,
                    this.priceList = response.data.priceList,
                    this.roomList = response.data.roomList,
                    this.ageList = response.data.ageList,
                    this.floorList = response.data.floorList,
                    this.totalPingList = response.data.totalPingList,
                    this.buildingPingList = response.data.buildingPingList,
                    this.landPingList = response.data.landPingList,
                    this.parkingList = response.data.parkingList,
                    this.vrList = response.data.vrList,
                    this.sellList = response.data.sellList,
                    document.title = this.meta.title,
                    $("meta[property='og:title']").attr("content", this.meta.title),
                    document.getElementsByTagName('meta')["description"].content = this.meta.description,
                    $("meta[property='og:description']").attr("content", this.meta.description),
                    this.start()
            ))
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
        $('.flex-column').click(function () {
            house.townList = [];
        });

        $("body").on("click", ".region-list button", function (event) {
            house.region = $(this).attr('region');
        });

        $("body").on("click", ".collection-page .page-link", function (event) {
            let page = $(this).attr('page');
            house.search(page);

            setTimeout(() => {
                window.scrollTo(0, document.querySelector("#totalRecord").offsetTop);
            }, 500)
        });

        $(document).click(function (event) {
            if ($(event.target).prop('tagName') != 'path'
                && $(event.target).prop('tagName') != 'svg'
                && $(event.target).attr('class') != 'icon'
                && $(event.target).attr('class') != 'btn btn-select'
                && $(event.target).attr('class') != 'btn btn-select selected'
                && $(event.target).closest('.select-dropdown').length == 0) {
                $('.dropOpen').removeClass('dropOpen');
            }
        });

        $(window).resize(function () {
            let nowWidth = $(window).width();
            house.width = nowWidth;
        });

        this.map = L.map('map').setView(this.leaflet.center, this.leaflet.zoom);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);

        this.map.on('zoomend', (event) => {
            console.log(this.map.getZoom())
            if (this.map.getZoom() == 13) {
                this.map.removeLayer(this.marker);
                for (let x = 0; x < this.areaList.length; x++) {
                    let area = this.areaList[x];
                    let areaIcon = L.divIcon({
                        city: area.city,
                        town: area.town,
                        center: area.center,
                        html: '<div><div>' + area.town + '<br>' + area.count + '</div></div>',
                        className: 'marker-cluster-area',
                        iconSize: new L.Point(70, 70),
                    });
                    this.divIcon.push(L.marker(area.center, {icon: areaIcon}).on('click', this.zoomIn).addTo(this.map));
                }
            } else if (this.map.getZoom() > 13 && this.divIcon.length > 0) {
                for (let x = 0; x < this.divIcon.length; x++) {
                    this.map.removeLayer(this.divIcon[x]);
                }
                this.divIcon = [];
                this.resetMarkerList(0)
            }
        });
    }
}
</script>
