<template>
    <router-link :to="item.url" target="_blank" @click="track('店頭-看物件', item.subject)">
        <div :class="'list-content' + (item.uuid == markerChecked ? ' checked' : '')" :style="showItem.length == 0 || showItem.indexOf(item.uuid) != -1 ? '' : 'display: none;'">
            <div class="list-body">
                <div class="thumbnail">
                    <div class="badge badge-view" v-if="item.vrLink != ''">全景</div>
                    <img :src="item.img" :alt="item.subject + '-' + siteName" class="w-100" v-if="item.img != ''">
                    <img :src="item.defaultImage" :alt="item.subject" class="w-100" v-else>
                </div>
                <div class="meta">
                    <h3 class="title">{{ item.subject }}</h3>
                    <div class="structure d-flex align-items-center">
                        <span class="me-2">{{ item.houseType }}</span>
                        <span>{{ item.pattern }}</span>
                    </div>
                    <div class="info-group d-flex align-items-center">
                        <span class="me-2">{{ item.ping }}</span>
                        <span v-if="item.age != ''">{{ item.age }}</span>
                        <div class="price ms-auto" v-if="item.sell == 2">{{ item.rentPrice }}元/月</div>
                        <div class="price ms-auto" v-else-if="item.lowerPrice > 0">{{ item.lowerPrice }} 萬</div>
                        <div class="price ms-auto" v-else>{{ item.total }} 萬</div>
                    </div>
                </div>
            </div>
            <div class="list-footer text-truncate">
                <IconPin class="icon d-inline-block me-2"/>
                <span class="address mapAddress">{{ item.address }}</span>
            </div>
        </div>
    </router-link>
</template>
<script>
import IconPin from '@/components/icons/IconPin.vue'

export default {
    name: 'CollectionMapList',
    props: {
        siteName: String,
        about: Object,
        item: Object,
        markerChecked: String,
        showItem: Array
    },
    components: {
        IconPin,
    },
    methods: {
        track: function (type, typeName) {
            if (this.about.ga != '') {
                ga('send', 'event', type, typeName);
            }
            if (this.about.pixel1 != '') {
                fbq('trackSingleCustom', this.about.pixel1, type, {houseType: typeName});
            }
            if (this.about.pixel2 != '') {
                fbq('trackSingleCustom', this.about.pixel2, type, {houseType: typeName});
            }
        },
    },
}
</script>

